import { chakra } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import IconSvg from 'ui/shared/IconSvg';

interface Props {
  className?: string;
}

const TestnetBadge = ({ className }: Props) => {
  if (!config.chain.isTestnet) {
    return null;
  }

  return <h4 style={{ 'color' : '#BC512F' , 'fontWeight': 'bold', 'fontSize': '12px'}}>Devnet</h4>;
};

export default React.memo(chakra(TestnetBadge));
